import { ActionButton, Icon, IconButton, Label, Link } from "@fluentui/react";
import React, { ChangeEvent, useRef, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Attachment } from "../../../../types/types";
import {
  getFileExtension,
  getFileIcon,
  getIconColor,
} from "../../../../utils/utils";

const AttachmentListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

const AttachmentListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutralLighterAlt};
  width: 350px;
`;

const AttachmentName = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.palette.neutralPrimary};
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
`;

const DropZone = styled.div<{ isDragging: boolean }>`
  font-size: 14px;
  color: ${(p) => p.theme.palette.neutralPrimary};
  border: 1px dashed #888888;
  padding: 10px 8px 8px 8px;
  text-align: center;
  margin-top: 10px;
  display: inline;
  background-color: ${(p) => (p.isDragging ? "#888888" : "transparent")};
`;

type Props = {
  attachments: Attachment[];
  setAttachments: (attachments: Attachment[]) => void;
  isReadOnly?: boolean;
};

export const AttachmentsList = ({
  attachments,
  setAttachments,
  isReadOnly,
}: Props) => {
  const { t } = useTranslation();
  const refFileInput = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState(false);

  const areSameFile = (file1Name: string, file2Name: string) =>
    file1Name === file2Name;

  const processFiles = useCallback(
    (files: FileList | null) => {
      if (!files) return;
      const fileArr = Array.from(files);
      const newAttachments = fileArr
        .filter(
          (f) => !attachments.some((a) => areSameFile(a.attachmentName, f.name))
        )
        .map((f) => {
          let attachmentName = f.name;
          if (f.name.length > 128) {
            const ext = f.name.split(".").slice(-1).toString();
            attachmentName =
              f.name.slice(0, 128 - (ext.length + 1)) + "." + ext;
          }
          return {
            attachmentName,
            attachmentMimeType: f.type,
            attachmentContent: f,
            attachmentSize: f.size,
          } as any as Attachment;
        });
      setAttachments([...attachments, ...newAttachments]);
    },
    [attachments, setAttachments]
  );

  const onChangeUploadFiles = (e: ChangeEvent<HTMLInputElement>) => {
    processFiles(e.target.files);
  };

  const onDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const onDragLeave = () => setIsDragging(false);

  const onDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    processFiles(e.dataTransfer.files);
  };

  return (
    <div onDragLeave={onDragLeave} onDrop={onDrop} onDragOver={onDragOver}>
      <Label>{t("ram.responsesPage.attachments")}</Label>
      {!isReadOnly && (
        <DropZone isDragging={isDragging}>
          <ActionButton
            iconProps={{ iconName: "CalculatorAddition" }}
            allowDisabledFocus
            onClick={() => refFileInput.current?.click()}
          >
            {isDragging ? "Drop files here..." : "Add attachment"}
          </ActionButton>
        </DropZone>
      )}

      <AttachmentListContainer>
        {attachments.map((att, index) => (
          <AttachmentListItem key={att.attachmentName}>
            <Icon
              iconName={getFileIcon(getFileExtension(att.attachmentName))}
              style={{
                color: getIconColor(getFileExtension(att.attachmentName)),
              }}
            />
            <AttachmentName>
              {att.attachmentPath ? (
                <Link href={att.attachmentPath} target="_blank">
                  {att.attachmentName}
                </Link>
              ) : (
                att.attachmentName
              )}
            </AttachmentName>
            {!isReadOnly && (
              <IconButton
                iconProps={{ iconName: "Cancel" }}
                onClick={() => {
                  setAttachments(attachments.filter((_, idx) => idx !== index));
                  if (refFileInput.current) refFileInput.current.value = "";
                }}
              />
            )}
          </AttachmentListItem>
        ))}
      </AttachmentListContainer>
      <input
        type="file"
        name="file"
        multiple
        style={{ display: "none" }}
        ref={refFileInput}
        onChange={onChangeUploadFiles}
      />
    </div>
  );
};
