import { useFormikContext } from "formik";
import { useEffect } from "react";

function useCalculateResultAsIf() {
  const { values, setFieldValue } = useFormikContext<any>();

  const calculateResultAsIf = (
    improvementPotentialCodeAsIf: number | null,
    weighting: number | null
  ) => {
    if (improvementPotentialCodeAsIf !== null && weighting !== null) {
      return weighting * improvementPotentialCodeAsIf;
    }
    return null;
  };

  const handleCalculateResultAsIf = () => {
    const resultAsIf = calculateResultAsIf(
      values?.improvementPotentialCodeAsIf?.value !== null
        ? values?.improvementPotentialCodeAsIf?.value
        : null,
      values?.weighting
    );
    setFieldValue("resultAsIf", resultAsIf);
  };

  useEffect(() => {
    handleCalculateResultAsIf();
  }, [values?.improvementPotentialCodeAsIf, values?.weighting]);
}

export default useCalculateResultAsIf;
