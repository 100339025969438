import i18next from "i18next";
import { call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api/api";
import { toast } from "../../components/FluentToast";
import { loadLocationsActions, updateLocation } from "../locations/locations";

import { loadClients } from "../clients/clients";
import { removeOne } from "../locations/locations";
import { handleAxiosError } from "../util";
import {
  applyFiltersLocations,
  endAssessmentActions,
  exportToZipRoutine,
  setExportToZipDialog,
  setSearchLocations,
  setSortLocations,
  startAssessmentActions,
  generateLocationReportDocumentRoutine,
  setGenerateLocationReportDocumentDialog,
  deleteLocationRoutine,
  setDeleteDialog,
  changeClientRoutine,
  setChangeClientDialog,
  usersReportDocumentRoutine,
} from "./locationsPage";

export function* sortLocationsSaga() {
  yield takeEvery(setSortLocations.type, function* () {
    yield put(applyFiltersLocations() as any);
  });
}

export function* searchLocationsSaga() {
  yield takeEvery(setSearchLocations.type, function* () {
    yield put(applyFiltersLocations() as any);
  });
}

export function* startAssessmentSaga() {
  yield takeEvery(
    startAssessmentActions.trigger,
    function* (a: ReturnType<typeof startAssessmentActions.trigger>) {
      try {
        yield put(startAssessmentActions.loading(a.payload));
        const res = yield call(API.startAssessment, a.payload);
        yield put(startAssessmentActions.success(res.data));
      } catch (err) {
        yield put(startAssessmentActions.error(err));
      }
    }
  );
}

export function* startAssessmentSuccessSaga() {
  yield takeEvery(
    startAssessmentActions.success,
    function* (a: ReturnType<typeof startAssessmentActions.success>) {
      const location = a.payload;
      yield put(
        updateLocation({
          id: location.locationId,
          changes: {
            assessmentPeriodStartedAt: new Date().toISOString(),
          },
        })
      );
      yield put(loadLocationsActions.trigger());
    }
  );
}

export function* endAssessmentSaga() {
  yield takeEvery(
    endAssessmentActions.trigger,
    function* (a: ReturnType<typeof endAssessmentActions.trigger>) {
      try {
        yield put(endAssessmentActions.loading(a.payload));
        const res = yield call(API.endAssessment, a.payload);
        yield put(endAssessmentActions.success(res.data));
      } catch (err) {
        yield put(endAssessmentActions.error(err));
      }
    }
  );
}

export function* endAssessmentSuccessSaga() {
  yield takeEvery(
    endAssessmentActions.success,
    function* (a: ReturnType<typeof endAssessmentActions.success>) {
      const location = a.payload;
      yield put(
        updateLocation({
          id: location.locationId,
          changes: {
            assessmentPeriodStartedAt: null,
          },
        })
      );
      yield put(loadLocationsActions.trigger());
    }
  );
}

export function* exportToZipSaga() {
  yield takeEvery(exportToZipRoutine.trigger, function* (a) {
    const { locationId } = a.payload;
    try {
      yield put(exportToZipRoutine.loading());
      yield call(API.exportToZip, locationId);
      toast.success(i18next.t("greco.success"));
      yield put(
        setExportToZipDialog({
          isOpen: false,
          locationId: null,
        })
      );
      yield put(exportToZipRoutine.success());
    } catch (err) {
      yield put(exportToZipRoutine.error(err));
      handleAxiosError(err);
    }
  });
}

export function* generateLocationReportDocumentSaga() {
  yield takeEvery(generateLocationReportDocumentRoutine.trigger, function* (a) {
    const { clientId, locationId, body } = a.payload;
    try {
      yield put(generateLocationReportDocumentRoutine.loading());
      const res = yield call(
        API.generateLocationReportDocument,
        clientId,
        locationId,
        body
      );
      toast.success(i18next.t("greco.success"));
      // yield put(
      //   setGenerateLocationReportDocumentDialog({
      //     isOpen: false,
      //     body: null,
      //     clientId: null,
      //     locationId: null,
      //   })
      // );
      yield put(generateLocationReportDocumentRoutine.success(res.data));
    } catch (err) {
      yield put(generateLocationReportDocumentRoutine.error(err));
      handleAxiosError(err);
    }
  });
}

export function* deleteLocationSaga() {
  yield takeEvery(deleteLocationRoutine.trigger, function* (a) {
    const { locationId } = a.payload;
    try {
      yield put(deleteLocationRoutine.loading());
      const res = yield call(API.deleteLocation, {
        locationId,
      });
      yield put(deleteLocationRoutine.success(res.data));
      yield put(removeOne(locationId));
      yield put(loadLocationsActions.trigger());
      toast.success(i18next.t("greco.success"));
      yield put(
        setDeleteDialog({
          isOpen: false,
          location: null,
        })
      );
    } catch (err) {
      yield put(deleteLocationRoutine.error(err));
      handleAxiosError(err);
    }
  });
}

export function* changeClientSaga() {
  yield takeEvery(changeClientRoutine.trigger, function* (a) {
    const { locationId, clientId } = a.payload;
    try {
      yield put(changeClientRoutine.loading());
      const res = yield call(API.changeClient, {
        locationId,
        clientId,
      });
      yield put(changeClientRoutine.success(res.data));
      yield put(removeOne(locationId));
      yield put(loadClients.trigger());
      yield put(loadLocationsActions.trigger());
      toast.success(i18next.t("greco.success"));
      yield put(
        setChangeClientDialog({
          isOpen: false,
          location: null,
        })
      );
    } catch (err) {
      yield put(changeClientRoutine.error(err));
      handleAxiosError(err);
    }
  });
}

export function* usersReportDocumentSaga() {
  yield takeEvery(usersReportDocumentRoutine.trigger, function* (a) {
    const { clientId, locationId } = a.payload;
    try {
      yield put(usersReportDocumentRoutine.loading());
      const res = yield call(API.locationsReportDocument, {
        clientId,
        locationId,
      });
      yield put(usersReportDocumentRoutine.success(res.data));

      toast.success(i18next.t("greco.success"));
      // yield put(
      //   setReportDocumentDialog({
      //     isOpen: false,
      //   })
      // );
    } catch (err: any) {
      yield put(usersReportDocumentRoutine.error(err));
      handleAxiosError(err);
    }
  });
}

export function* locationsPageSaga() {
  yield fork(sortLocationsSaga);
  yield fork(searchLocationsSaga);
  yield fork(startAssessmentSaga);
  yield fork(startAssessmentSuccessSaga);
  yield fork(endAssessmentSaga);
  yield fork(endAssessmentSuccessSaga);
  yield fork(exportToZipSaga);
  yield fork(generateLocationReportDocumentSaga);
  yield fork(deleteLocationSaga);
  yield fork(changeClientSaga);
  yield fork(usersReportDocumentSaga);
}
