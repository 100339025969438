import React, { useEffect } from "react";
import CheckboxField from "../../../components/controls/CheckboxField";
import { useFormikContext } from "formik";
import RadioGroupField from "../../../components/controls/RadioGroupField";
import { useSelector } from "../../../store/hooks";
import ComboBoxField from "../../../components/controls/ComboBoxField";
import useGetTaxonomyOptions from "../../../utils/hooks/useGetTaxonomyOptions";
import { useTranslation } from "react-i18next";
import { mergeStyleSets } from "@fluentui/merge-styles";
import TextInputField from "components/controls/TextInputField";
import { UserRole } from "types/types";

function FormContent() {
  const { t } = useTranslation();
  const userRole = useSelector((s) => s.userRole.userRole);
  const reports = useSelector((s) => s.reports.entities);
  const taxonomy = useSelector((s) => s.taxonomy);

  const riskClientReportCreatorId =
    taxonomy.RiskReportCreator.byCode["RiskReportCreator.RISK_CLIENT"]?.id;

  const { locationId } = useSelector(
    (s) => s.locationsPage.generateLocationReportDocumentDialog
  );

  const locationReports = Object.values(reports).filter(
    (r) => r.locationId === locationId && r.isReportDeactivated === false
  );

  const { values, errors, setFieldValue, handleChange } =
    useFormikContext<any>();

  const riskCategoryOptions = useGetTaxonomyOptions({
    taxonomyKey: "RiskAssessmentCategory",
  });
  const riskCategoryOfMeasureOptions = useGetTaxonomyOptions({
    taxonomyKey: "RiskCategoryOfMeasure",
  });

  const riskImplementationStatusOptions = useGetTaxonomyOptions({
    taxonomyKey: "RiskImplementationStatus",
  });

  const riskPriorityOptions = useGetTaxonomyOptions({
    taxonomyKey: "RiskPriority",
    include: [
      "RiskPriority.MINOR",
      "RiskPriority.MODERATE",
      "RiskPriority.MAJOR",
    ],
  });

  useEffect(() => {
    // if (values?.reportType === "Client") {
    setFieldValue("reportIds", []);
    // } else {
    //   setFieldValue("reportIds", []);
    // }
  }, [values?.reportType]);

  const reportIdOptions = () => {
    let options = [];
    if (values.reportType === "Client") {
      options = locationReports.filter(
        (r) => r.reportCreatorCode === riskClientReportCreatorId
      );
    } else {
      options = locationReports.filter(
        (r) => r.reportCreatorCode !== riskClientReportCreatorId
      );
    }
    options = options.map((r) => ({
      value: r.reportId,
      label: r.reportName,
    }));

    // if (values.reportType === "GREG") {
    // }
    return options;
  };

  const reportTypeOptions = () => {
    let options = [];
    // Samo za exksternog, external advanced usera i za admina
    if (userRole === "external" || userRole === "external_advanced") {
      options.push({
        key: "Client",
        text: t(
          "ram.locationList.context.generateLocationReportDocument.reportTypeClient"
        ),
      });
    } else {
      options.push({
        key: "GREG",
        text: t(
          "ram.locationList.context.generateLocationReportDocument.reportTypeGREG"
        ),
      });
      if (userRole === "admin") {
        options.push({
          key: "Client",
          text: t(
            "ram.locationList.context.generateLocationReportDocument.reportTypeClient"
          ),
        });
      }
      // options.push({
      //   key: "VMG",
      //   text: t(
      //     "ram.locationList.context.generateLocationReportDocument.reportTypeVMG"
      //   ),
      // });
    }
    return options;
  };

  return (
    <div className={classes.grid}>
      <CheckboxField
        checked={values?.useGermanTemplate}
        errors={errors}
        setFieldValue={setFieldValue}
        name={"useGermanTemplate"}
        disabled={false}
        label={"ram.locationList.useGermanTemplate"}
        required={false}
      />
      <div></div>
      <RadioGroupField
        checked={values?.reportType}
        errors={errors}
        setFieldValue={setFieldValue}
        name={"reportType"}
        disabled={false}
        label={
          "ram.locationList.context.generateLocationReportDocument.reportType"
        }
        options={reportTypeOptions()}
        required={true}
      />
      <ComboBoxField
        isMulti
        required={false}
        disabled={false}
        errors={errors}
        name="reportIds"
        label={"ram.recommendations.column.reports.label"}
        setFieldValue={setFieldValue}
        options={reportIdOptions()}
        defaultValue={values?.reportIds}
        confidentiality={null}
      />
      <ComboBoxField
        isMulti
        required={false}
        disabled={false}
        errors={errors}
        name="riskCategory"
        label={"ram.recommendations.column.mainCategoryCode.label"}
        setFieldValue={setFieldValue}
        options={riskCategoryOptions}
        defaultValue={values?.riskCategory}
        confidentiality={null}
      />
      <ComboBoxField
        isMulti
        required={false}
        disabled={false}
        errors={errors}
        name="riskCategoryOfMeasure"
        label={"ram.recommendationForm.typeCode.label"}
        setFieldValue={setFieldValue}
        options={riskCategoryOfMeasureOptions}
        defaultValue={values?.riskCategoryOfMeasure}
      />
      <ComboBoxField
        isMulti
        required={false}
        disabled={false}
        errors={errors}
        name="riskPriorityInsurer"
        label={"ram.recommendationForm.priorityCode.label"}
        setFieldValue={setFieldValue}
        options={riskPriorityOptions}
        defaultValue={values?.riskPriorityInsurer}
      />
      <ComboBoxField
        isMulti
        required={false}
        disabled={false}
        errors={errors}
        name="riskPriorityCompany"
        label={"ram.recommendationForm.priorityCompanyCode.label"}
        setFieldValue={setFieldValue}
        options={riskPriorityOptions}
        defaultValue={values?.riskPriorityCompany}
      />{" "}
      <ComboBoxField
        isMulti
        required={false}
        disabled={false}
        errors={errors}
        name="riskImplementationStatus"
        label={"ram.recommendationForm.statusGrECoCode.label"}
        setFieldValue={setFieldValue}
        options={riskImplementationStatusOptions}
        defaultValue={values?.riskImplementationStatus}
      />
      <TextInputField
        value={values?.internalNumberContainsText}
        onChange={handleChange}
        errors={errors}
        name={"internalNumberContainsText"}
        disabled={false}
        label={"ram.recommendationForm.internalNumberContainsText.label"}
      />
    </div>
  );
}

const classes = mergeStyleSets({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  grid: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "20px",
    overfow: "auto",
  },
  fullWidth: {
    width: "100%",
  },
});

export default FormContent;
