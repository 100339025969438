import { IconButton, Label, Link } from "@fluentui/react";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Truncate } from "../../components/styled";
import { useSelector } from "../../store/hooks";
import styled from "styled-components";
import { FieldContainer } from "../../components/controls/FieldContainer";

export const ReportDocument = ({ values, setFieldValue }) => {
  const { t } = useTranslation();
  const refInput = useRef<HTMLInputElement>();
  const report = useSelector((s) => s.reportPage.report);
  const isDisabled = report?.isReportDeactivated === true;

  let documentPath;
  if (values.reportDocumentPath) {
    documentPath = values.reportDocumentPath;
  }

  let documentName;

  if (values?.file) {
    documentName = values?.file.name;
  }

  let documentExists = values?.reportDocumentPath || values?.file;

  return (
    <FieldContainer
      isTooltipHidden={false}
      tooltipText={"ram.reportForm.reportDocumentContent.tooltip"}
    >
      <Label>{t("ram.reportForm.reportDocumentContent.label")}</Label>
      <Field>
        <IconButton
          iconProps={{ iconName: "CalculatorAddition" }}
          onClick={() => {
            if (!refInput.current) return;
            refInput.current.click();
          }}
          disabled={isDisabled}
          style={{
            marginBottom: 1,
          }}
        />
        {!documentName && documentPath && (
          <StyledLink onClick={() => window.open(documentPath)}>
            {t("ram.reportForm.uploadedReport")}
          </StyledLink>
        )}

        {documentName && <DocumentName>{documentName}</DocumentName>}
        {documentExists && (
          <IconButton
            iconProps={{ iconName: "Cancel" }}
            onClick={() => {
              setFieldValue("reportDocumentContent", null);
              setFieldValue("reportDocumentMimeType", null);
              setFieldValue("reportDocumentPath", null);
              setFieldValue("file", null);
            }}
          />
        )}
        <input
          type="file"
          style={{
            display: "none",
          }}
          ref={refInput}
          onChange={(e) => {
            setFieldValue("file", e.target.files[0]);
          }}
        />
      </Field>
    </FieldContainer>
  );
};

const DocumentName = styled(Truncate)`
  font-size: 14px;
  flex: 1;
  width: 0;
  color: ${(p) => p.theme.palette.neutralPrimary};
`;

const Field = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  border-bottom: 1px solid ${(p) => p.theme.palette.black};
  gap: 5px;
`;

const StyledLink = styled(Link)`
  font-size: 14px;
`;
