import { Form, Formik } from "formik";
import { Dialog, DialogFooter, Icon } from "@fluentui/react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormSpinner } from "../../../components/FormSpinner";
import {
  Flex,
  HSpace,
  StyledPrimaryButton,
  VSpace,
} from "../../../components/styled";
import { RefProvider } from "../../../contexts/RefProvider";
import { useSelector } from "../../../store/hooks";
import {
  generateLocationReportDocumentRoutine,
  setGenerateLocationReportDocumentDialog,
  setLocationReportDocument,
} from "../../../store/locations-page/locationsPage";
import FormContent from "./FormContent";
import { SaveButton } from "./SaveButton";

export const GenerateLocationReportDocumentDialog = () => {
  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { clientId, locationId } = useSelector(
    (s) => s.locationsPage.generateLocationReportDocumentDialog
  );

  const locationReportDocument = useSelector(
    (s) => s.locationsPage.locationReportDocument
  );

  const isLoading = useSelector(
    (s) =>
      s.locationsPage.generateLocationReportDocumentLoadStatus === "loading"
  );
  const userRole = useSelector((s) => s.userRole.userRole);

  const sortByField = useSelector((s) => s.recommendationsPage.sort.key);
  const isSortAscending = useSelector(
    (s) => s.recommendationsPage.sort.dir === "asc"
  );
  const onSubmit = (data) => {
    const payload: {
      useGermanTemplate: boolean | null;
      riskCategory: number[];
      riskCategoryOfMeasure: number[];
      riskImplementationStatus: number[];
      riskPriorityCompany: number[];
      riskPriorityInsurer: number[];
      isClientReport?: boolean;
      internalNumberContainsText: string | null;
      sortByField?: string;
      isSortAscending?: boolean;
      reportIds?: number[];
    } = {
      useGermanTemplate: data?.useGermanTemplate || null,
      // reportType:data?.reportType
      riskCategory: data?.riskCategory?.map((item) => item?.value) || [],
      riskCategoryOfMeasure:
        data?.riskCategoryOfMeasure?.map((item) => item?.value) || [],
      riskImplementationStatus:
        data?.riskImplementationStatus?.map((item) => item?.value) || [],
      riskPriorityCompany:
        data?.riskPriorityCompany?.map((item) => item?.value) || [],
      riskPriorityInsurer:
        data?.riskPriorityInsurer?.map((item) => item?.value) || [],
      internalNumberContainsText: data?.internalNumberContainsText || null,
      sortByField: sortByField,
      isSortAscending: isSortAscending,
      reportIds: data?.reportIds?.map((item) => item?.value) || [],
    };

    if (data.reportType === "Client") {
      payload.isClientReport = true;
    }

    dispatch(
      generateLocationReportDocumentRoutine.trigger({
        clientId: clientId,
        locationId: locationId,
        body: {
          locationId: locationId,
          ...payload,
        },
      })
    );
  };

  const close = () => {
    dispatch(
      setGenerateLocationReportDocumentDialog({
        clientId: null,
        locationId: null,
        body: {
          reportType:
            userRole === "external" || userRole === "external_advanced"
              ? "Client"
              : "GREG",
          riskCategory: null,
          riskCategoryOfMeasure: null,
          riskImplementationStatus: null,
          riskPriorityInsurer: null,
          riskPriorityCompany: null,
          internalNumberContainsText: null,
        },
        isOpen: false,
      })
    );
    dispatch(setLocationReportDocument(null));
  };

  const initialValues = useMemo(() => {
    return {
      reportType:
        userRole === "external" || userRole === "external_advanced"
          ? "Client"
          : "GREG",
      riskCategory: null,
      riskCategoryOfMeasure: null,
      riskImplementationStatus: null,
      riskPriorityInsurer: null,
      riskPriorityCompany: null,
      internalNumberContainsText: null,
    };
  }, []);

  console.log(locationReportDocument);
  return (
    <Dialog
      maxWidth={1400}
      minWidth={1000}
      hidden={false}
      dialogContentProps={{
        showCloseButton: true,
        title: (
          <Flex>
            <Icon iconName="Info" /> <HSpace />
            <div>
              {t("ram.locationList.context.generateLocationReportDocument")}
            </div>
          </Flex>
        ),
      }}
      onDismiss={close}
      isBlocking
    >
      <RefProvider>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange={didTrySubmit}
          validateOnBlur={didTrySubmit}
        >
          {({ submitForm }) => {
            return (
              <Form>
                {/* <Fields config={config} /> */}
                {!locationReportDocument && !isLoading && <FormContent />}
                {locationReportDocument &&
                  locationReportDocument?.url === null && (
                    <div>
                      {t("ram.reportDocumentDialogDownloadNoReports.label")}
                    </div>
                  )}
                {/* <VSpace height={10} />
                <div>
                  {t(
                    "ram.locationList.context.generateLocationReportDocument.success"
                  )}
                </div> */}
                <VSpace height={10} />

                <DialogFooter>
                  {locationReportDocument !== null ? (
                    <StyledPrimaryButton
                      iconProps={{
                        iconName: locationReportDocument.url
                          ? "Download"
                          : "Cancel",
                      }}
                      text={
                        locationReportDocument.url
                          ? t("ram.reportDocumentDialogDownload.label")
                          : t("ram.reportDocumentDialogDownloadClose.label")
                      }
                      onClick={() => {
                        if (locationReportDocument.url) {
                          window.open(locationReportDocument.url, "_blank");
                        }
                        close();
                      }}
                      disabled={isLoading}
                    />
                  ) : (
                    <SaveButton
                      isDisabled={isLoading}
                      onClick={() => {
                        setDidTrySubmit(true);
                        submitForm();
                      }}
                    />
                  )}
                </DialogFooter>

                {isLoading && <FormSpinner />}
              </Form>
            );
          }}
        </Formik>
      </RefProvider>
    </Dialog>
  );
};
